export default [
  {
    name: 'TWITTER',
    url: 'https://twitter.com/jikantoki',
    icon: 'mdi-twitter'
  },
  {
    name: 'HOMEPAGE',
    url: 'https://enoki.xyz',
    icon: 'mdi-earth'
  },
  {
    name: 'GITHUB',
    url: 'https://github.com/jikantoki',
    icon: 'mdi-github'
  }
]
